exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-alumni-jsx": () => import("./../../../src/pages/alumni.jsx" /* webpackChunkName: "component---src-pages-alumni-jsx" */),
  "component---src-pages-apply-jsx": () => import("./../../../src/pages/apply.jsx" /* webpackChunkName: "component---src-pages-apply-jsx" */),
  "component---src-pages-campuses-jsx": () => import("./../../../src/pages/campuses.jsx" /* webpackChunkName: "component---src-pages-campuses-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-company-jsx": () => import("./../../../src/pages/company.jsx" /* webpackChunkName: "component---src-pages-company-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-corporate-training-jsx": () => import("./../../../src/pages/corporate-training.jsx" /* webpackChunkName: "component---src-pages-corporate-training-jsx" */),
  "component---src-pages-course-retirement-jsx": () => import("./../../../src/pages/course-retirement.jsx" /* webpackChunkName: "component---src-pages-course-retirement-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-isa-calculator-jsx": () => import("./../../../src/pages/isa-calculator.jsx" /* webpackChunkName: "component---src-pages-isa-calculator-jsx" */),
  "component---src-pages-isa-jsx": () => import("./../../../src/pages/isa.jsx" /* webpackChunkName: "component---src-pages-isa-jsx" */),
  "component---src-pages-mental-health-support-jsx": () => import("./../../../src/pages/mental-health-support.jsx" /* webpackChunkName: "component---src-pages-mental-health-support-jsx" */),
  "component---src-pages-miziwe-biik-jsx": () => import("./../../../src/pages/miziwe-biik.jsx" /* webpackChunkName: "component---src-pages-miziwe-biik-jsx" */),
  "component---src-pages-our-courses-jsx": () => import("./../../../src/pages/our-courses.jsx" /* webpackChunkName: "component---src-pages-our-courses-jsx" */),
  "component---src-pages-purchase-thank-you-jsx": () => import("./../../../src/pages/purchase-thank-you.jsx" /* webpackChunkName: "component---src-pages-purchase-thank-you-jsx" */),
  "component---src-pages-reviews-jsx": () => import("./../../../src/pages/reviews.jsx" /* webpackChunkName: "component---src-pages-reviews-jsx" */),
  "component---src-pages-scholarship-jsx": () => import("./../../../src/pages/scholarship.jsx" /* webpackChunkName: "component---src-pages-scholarship-jsx" */),
  "component---src-pages-style-guide-colors-jsx": () => import("./../../../src/pages/style-guide-colors.jsx" /* webpackChunkName: "component---src-pages-style-guide-colors-jsx" */),
  "component---src-pages-style-guide-components-jsx": () => import("./../../../src/pages/style-guide-components.jsx" /* webpackChunkName: "component---src-pages-style-guide-components-jsx" */),
  "component---src-pages-style-guide-jsx": () => import("./../../../src/pages/style-guide.jsx" /* webpackChunkName: "component---src-pages-style-guide-jsx" */),
  "component---src-pages-style-guide-typography-jsx": () => import("./../../../src/pages/style-guide-typography.jsx" /* webpackChunkName: "component---src-pages-style-guide-typography-jsx" */),
  "component---src-pages-why-choose-juno-college-bootcamp-jsx": () => import("./../../../src/pages/why-choose-juno-college-bootcamp.jsx" /* webpackChunkName: "component---src-pages-why-choose-juno-college-bootcamp-jsx" */),
  "component---src-templates-blog-category-jsx": () => import("./../../../src/templates/blog-category.jsx" /* webpackChunkName: "component---src-templates-blog-category-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blog-post-journey-jsx": () => import("./../../../src/templates/blog-post-journey.jsx" /* webpackChunkName: "component---src-templates-blog-post-journey-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-blog-post-partner-jsx": () => import("./../../../src/templates/blog-post-partner.jsx" /* webpackChunkName: "component---src-templates-blog-post-partner-jsx" */),
  "component---src-templates-book-call-jsx": () => import("./../../../src/templates/book-call.jsx" /* webpackChunkName: "component---src-templates-book-call-jsx" */),
  "component---src-templates-bootcamp-details-jsx": () => import("./../../../src/templates/bootcamp-details.jsx" /* webpackChunkName: "component---src-templates-bootcamp-details-jsx" */),
  "component---src-templates-career-pathways-jsx": () => import("./../../../src/templates/career-pathways.jsx" /* webpackChunkName: "component---src-templates-career-pathways-jsx" */),
  "component---src-templates-course-details-jsx": () => import("./../../../src/templates/course-details.jsx" /* webpackChunkName: "component---src-templates-course-details-jsx" */),
  "component---src-templates-employee-bio-jsx": () => import("./../../../src/templates/employee-bio.jsx" /* webpackChunkName: "component---src-templates-employee-bio-jsx" */),
  "component---src-templates-event-landing-page-jsx": () => import("./../../../src/templates/event-landing-page.jsx" /* webpackChunkName: "component---src-templates-event-landing-page-jsx" */),
  "component---src-templates-generic-page-jsx": () => import("./../../../src/templates/generic-page.jsx" /* webpackChunkName: "component---src-templates-generic-page-jsx" */),
  "component---src-templates-grad-showcase-jsx": () => import("./../../../src/templates/grad-showcase.jsx" /* webpackChunkName: "component---src-templates-grad-showcase-jsx" */),
  "component---src-templates-hire-a-grad-jsx": () => import("./../../../src/templates/hire-a-grad.jsx" /* webpackChunkName: "component---src-templates-hire-a-grad-jsx" */),
  "component---src-templates-legalese-jsx": () => import("./../../../src/templates/legalese.jsx" /* webpackChunkName: "component---src-templates-legalese-jsx" */),
  "component---src-templates-thank-you-jsx": () => import("./../../../src/templates/thank-you.jsx" /* webpackChunkName: "component---src-templates-thank-you-jsx" */)
}

